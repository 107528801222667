<script setup lang="ts">
import type { Schemas } from "#shopware";
import { getTranslatedProperty, getCategoryRoute } from "@shopware/helpers";

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const props = defineProps<{
  category: Schemas["Category"];
}>();
const { category } = toRefs(props);
</script>

<template>
  <NuxtLink
    :to="formatLink(getCategoryRoute(category))"
    class="p-3 flex flex-col gap-2 bg-scheppach-shades-0 b b-scheppach-primary-700 hover:b-scheppach-primary-300 rd hover:cursor-pointer hover:bg-scheppach-primary-50 duration-150"
  >
    <NuxtImg
      :src="category.media?.url"
      :width="165"
      :height="165"
      sizes="165px 201px 268px 322px"
      class="w-full min-h-35 sm:min-h-40 object-cover"
      alt="Category Card"
    />
    <p
      class="text-center c-scheppach-primary-700 text-[17px] lg:text-[20px] inset-inline-0 mt-2"
    >
      {{ getTranslatedProperty(category, "name") }}
    </p>
  </NuxtLink>
</template>
